.custom {
  /*width: 370px;*/
}

.hidden {
  display: none !important;
}

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1350px,
);

body {
  /*
   --ion-grid-width-xs: 100%;
   --ion-grid-width-sm: 540px;
   --ion-grid-width-md: 720px;
   --ion-grid-width-lg: 960px;
   --ion-grid-width-xl: 1850px;*/
}

$screen-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1350px,
  xxl: 1500px,
  ) !default;

@each $keySize, $valueSize in $screen-breakpoints {
  [data-size-#{$keySize}="1"] {
    @media (min-width: $valueSize) {
      flex: 0 0 calc(calc(1 / var(--ion-grid-columns, 12)) * 100%
      );
    width: calc(calc(1 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(1 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-size-#{$keySize}="2"] {
  @media (min-width: $valueSize) {
    flex: 0 0 calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-size-#{$keySize}="3"] {
  @media (min-width: $valueSize) {
    flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-size-#{$keySize}="4"] {
  @media (min-width: $valueSize) {
    flex: 0 0 calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-size-#{$keySize}="5"] {
  @media (min-width: $valueSize) {
    flex: 0 0 calc(calc(5 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(5 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(5 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-size-#{$keySize}="6"] {
  @media (min-width: $valueSize) {
    flex: 0 0 calc(calc(6 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-size-#{$keySize}="7"] {
  @media (min-width: $valueSize) {
    flex: 0 0 calc(calc(7 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(7 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(7 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-size-#{$keySize}="8"] {
  @media (min-width: $valueSize) {
    flex: 0 0 calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-size-#{$keySize}="9"] {
  @media (min-width: $valueSize) {
    flex: 0 0 calc(calc(9 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(9 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(9 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-size-#{$keySize}="10"] {
  @media (min-width: $valueSize) {
    flex: 0 0 calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-size-#{$keySize}="11"] {
  @media (min-width: $valueSize) {
    flex: 0 0 calc(calc(11 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(11 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(11 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-size-#{$keySize}="12"] {
  @media (min-width: $valueSize) {
    flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
  }
}

[data-visible-#{$keySize}] {
  display: none !important;

  @media (min-width: $valueSize) {
    display: block !important;
  }
}

[data-hidden-#{$keySize}] {
  display: block !important;

  @media (min-width: $valueSize) {
    display: none !important;
  }
}

[hidden-#{$keySize}] {
  @media (max-width: $valueSize) {
    display: none !important;
  }
}
}

@each $keySize, $valueSize in $screen-breakpoints {
  [visible-#{$keySize}] {
    display: none !important;

    @media (max-width: $valueSize) {
      display: block !important;
    }
  }
}

* {}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  ion-modal {
    --width: 750px;
    --height: calc(100vh - 92px);
  }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  ion-modal {
    --width: 750px;
    --height: calc(100vh - 92px);
  }
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  ion-modal.center-modal {
    --width: 750px;
    --height: 500px;
  }
}


ion-toolbar.nobg {
  --background: transparent;
}

ion-item.custom-border {
  --border-color: transparent;
  --border-width: 0 0 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

ion-item.custom-border:after {}

/*
ion-item[color="default"]{
  --background:transparent;
  --border-width: 0 0 0 0;
}*/
ion-calendar-week>ion-toolbar {
  display: none !important;
}

body.backdrop-no-scroll .ion-page {
  overflow-y: auto !important;
}

.nav-grid {
  height: 100%;
}

.nav-row {
  height: 100%;
}

.nav-col {
  min-width: 350px;
  background: #fff;
  height: calc(100vh - 56px);
  border-left: 1px solid #ccc;
  overflow: hidden;
  overflow-y: auto;
}

.nav-content {
  min-width: calc(100% - 700px);
  background: #ffffff;
  height: calc(100vh - 56px);
  border-left: 1px solid #ccc;
  overflow: hidden;
  overflow-y: auto;
}

.nav-col:eq(1) {
  z-index: 1;
}

.nav-col:eq(2) {
  z-index: 2;
}

.nav-content {
  z-index: 3;
}

ion-card-content.header,
.header {
  background: #f6f6f6;
}

.bg-header {
  background: #ddeaeb;
}

ion-toolbar ion-title {
  font-size: 1.2em;
}

ion-calendar-week {}

ion-content {
  --background: #bdc0cb;
}

@media (prefers-color-scheme: dark) {
  ion-content {
    --background: #363636;
  }
}

.helper_position {
  position: absolute;
  width: 1px;
  height: 1px;
  background: green;
}

.helper_demo_1 {
  background: red;
  position: absolute;
  width: 60px;
  height: 5px;
  margin-left: -30px;
  margin-top: -30px;
  z-index: 10000;
  content: " ";
  user-select: none;
}

.helper_demo_2 {
  background: red;
  position: absolute;
  width: 65px;
  height: 5px;
  margin-left: -30px;
  margin-top: 30px;
  z-index: 10000;
  content: " ";
  user-select: none;
}

.helper_demo_3 {
  background: red;
  position: absolute;
  width: 5px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  z-index: 10000;
  content: " ";
  user-select: none;
}

.helper_demo_4 {
  background: red;
  position: absolute;
  width: 5px;
  height: 60px;
  margin-left: 30px;
  margin-top: -30px;
  z-index: 10000;
  content: " ";
  user-select: none;
}

.normal-space {
  white-space: normal !important;
  line-height: 1.4em;
  padding: 4px 0 4px 10px;
}

ion-fab-button {
  --box-shadow: none;
}

ion-fab-button.smaller {
  margin: 0px;
  width: 30px;
  height: 30px;
}

ion-modal ion-content {
  --background: #fff;
}

app-step {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
}

app-step.noscroll {
  width: 100%;
  height: auto;
  overflow: initial;
  display: block;
}

app-step.scroll {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: block;
}

app-step ion-slides {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  display: block;
}

app-step ion-slides {
  width: 100%;
  height: calc(100% - 56px);
  overflow: hidden !important;
  display: block;
}

app-step.autoHeight ion-slides {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  display: block;
}

app-step ion-slides ion-slide {
  width: 100%;
  height: 100%;
  overflow-y: auto !important;
  min-width: 100%;
  display: block !important;
}

.list-md {
  padding: 0;
}

@media screen and (min-width: 1600px) {
  .nav-col {
    min-width: 25%;
    background: #fff;
    height: calc(100vh - 56px);
    border-left: 1px solid #ccc;
    overflow: hidden;
    overflow-y: auto;
  }

  .nav-content {
    min-width: 50%;
    background: #ffffff;
    height: calc(100vh - 56px);
    border-left: 1px solid #ccc;
    overflow: hidden;
    overflow-y: auto;
  }
}

@media (prefers-color-scheme: dark) {
  .nav-col {
    min-width: 25%;
    background: #1d1d1d;
    height: calc(100vh - 56px);
    border-left: 1px solid #323232;
    overflow: hidden;
    overflow-y: auto;
  }

  .nav-content {
    min-width: 50%;
    background: #1d1d1d;
    height: calc(100vh - 56px);
    border-left: 1px solid #323232;
    overflow: hidden;
    overflow-y: auto;
  }

  ion-card-content.header {
    background: #2b2b2b;
    color: #f1f1f1;
  }

  ion-toolbar ion-title {
    color: #8b8b8b;
  }
}

@media screen and (max-width: 1300px) {
  .nav-col {
    position: fixed;
    left: 0;
  }

  .nav-content {
    position: fixed;
    left: 350px;
    width: calc(100% - 350px);
  }
}

.hidden-first {
  display: none;
}

.hidden-no-first {
  display: block;
}

@media screen and (max-width: 900px) {
  .hidden-no-first {
    display: none;
  }

  .hidden-first {
    display: block;
  }

  .nav-col {
    position: fixed;
    left: 0;
  }

  .nav-content {
    width: 100%;
    position: fixed;
    left: 0;
    display: none;
  }

  .nav-col:not(.hidden)+.nav-content:not(.first) {
    display: block;
  }

  .nav-col:not(.hidden)+.nav-col.hidden+.nav-content:not(.first) {
    display: block;
  }
}

ion-item.info {
  min-height: 0px;
  padding: 0;

  ion-icon {
    margin: 0 0 0 15px;
  }

  ion-text {
    min-height: 0;
    padding: 1em;
  }
}

ion-icon.first {}

/* SCSS RGB */
$dark-jungle-green: rgba(4, 27, 21, 1);
$pine-green: rgba(19, 111, 99, 1);
$light-sea-green: rgba(34, 170, 161, 1);
$turquoise: rgba(76, 224, 210, 1);
$sky-blue: rgba(132, 202, 231, 1);

.sc-ion-popover-md-h {
  --width: 450px;
  --max-width: 90vw;
}

@media only screen and (min-width: 768px) and (min-height: 900px) {
  .sc-ion-modal-md-h {
    --height: 800px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  .sc-ion-modal-md-h {
    --height: 600px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  .sc-ion-modal-md-h {
    --height: 500px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  .sc-ion-modal-md-h {
    --height: 80vh;
  }
}

[data-container-uid="modal-content"] {
  ion-card-content {
    padding: 0;
    height: 100%;
    overflow: hidden;

    >ion-grid {
      padding: inherit;
      height: 100%;
      overflow: hidden;

      >ion-row {
        padding: inherit;
        height: 100%;
        overflow: hidden;

        >ion-col {
          padding: inherit;
          height: 100%;
          overflow-y: auto;
          max-width: 600px;
        }

        >ion-col+ion-col {
          padding: inherit;
          height: 100%;
          overflow-y: auto;
          max-width: calc(100% - 600px);
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  [data-container-uid="modal-content"] {
    >ion-card-content {
      padding: 0;
      height: auto;
      overflow: auto;
      display: block;

      >ion-grid {
        display: block;
        padding: inherit;
        height: inherit;

        >ion-row {
          display: block;
          padding: inherit;
          height: inherit;

          >ion-col {
            max-width: 100%;
            display: block;
          }

          >ion-col+ion-col {
            max-width: 100%;
            display: block;
          }
        }
      }
    }
  }
}

app-modal app-step ion-grid {
  height: 100%;
}

app-modal app-step ion-grid ion-row:nth-child(1) {
  height: calc(100% - 100px);
  overflow-y: auto;
}

app-modal app-step ion-grid ion-row::nth-child(1) ion-col {
  height: 100%;
}

.modal-col-right {
  height: 100%;
}

.sc-ion-modal-md-h,
.sc-ion-modal-md-h.large {
  .modal-col-right {
    display: none;
  }
}

.modal-fullscreen .modal-col-right {
  display: block !important;
}

@media only screen and (min-width: 1000px) {
  ion-modal.large:not(.modal-fullscreen) {
    --width: 980px;

    .modal-col-right {
      display: block;
    }
  }

  .modal-fullscreen {
    --width: 980px;
  }
}

@media only screen and (min-width: 1300px) {
  ion-modal.large:not(.modal-fullscreen) {
    --width: 1200px;

    .modal-col-right {
      display: block;
    }
  }

}

.fullscrean {
  width: 100%;
  height: 100%;
  position: fixed;
}

.modal-fullscreen {
  --width: 100%;
  --height: 100%;
}

.center-modal {
  --width: 750px;
  --height: 50%;
  --min-height: 600px;
}

.modal-fullscreen {
  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}

ion-modal {
  /* --background:#f6f3ec !important;
  --border-radius:1em !important;*/
}

ion-modal ion-content {
  /*--background:#f6f3ec !important;*/
}

ion-modal ion-toolbar {
  --background: var(--ion-color-primary, #3880ff) !important;
  --color: var(--ion-color-primary-contrast, #fff) !important;

  ion-buttons {
    font-weight: bold; //works
    --icon-font-size: 10px !important;
  }

  ion-back-button {
    --icon-font-size: 30px !important;
  }
}

ion-modal ion-calendar ion-toolbar {
  --background: #fff !important;
  --color: #2d2d2d !important;
}

.drop-hover {
  border: 2px dashed #a78a48 !important;
}

/*
form ion-item {
  --background: #f8f6f3 !important;
  //--border-radius: 1em;
  --color:#000;
  --highlight-color-focused:transparent;
  --border-color:#a78a48;
  --border-radius:0;
  --border-style:dashed;

  margin-bottom: 1em;
}
*/

ion-grid {
  --ion-grid-padding: 0;
  --ion-grid-padding-lg: 0;
  --ion-grid-padding-md: 0;
  --ion-grid-padding-sm: 0;
  --ion-grid-padding-xl: 0;
  --ion-grid-padding-xs: 0;
  /*  --ion-grid-column-padding:2px;
   --ion-grid-column-padding-lg:2px;
   --ion-grid-column-padding-md:2px;
   --ion-grid-column-padding-sm:2px;
   --ion-grid-column-padding-xl:2px;
   --ion-grid-column-padding-xs:2px;*/
}

ion-segment {
  --background: transparent;
}

ion-segment-button {
  --indicator-box-shadow: transparent !important;
  /* border-radius: 5px !important;
   padding: 5px;
   margin: 0;*/
  --background: #f1f1f1;
  --color: #000;
  --background-checked: #015f93 !important;
  --color-checked: white;
  --indicator-color: transparent !important;
  /* this solution */
}

ion-segment-button.danger {
  border-bottom: 15px solid red !important;
}

.segment-button-indicator-background {
  background: red !important;
}

.in-item {
  margin: 0.75em 0 !important;
}

ion-select {
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-start: 1em;
}

ion-item {
  --padding-start: 0;
  font-size: 15px;
}

/*
 .textarea-wrapper.sc-ion-textarea-md {
   margin: 5px;
   background: #F1F1F1;
 }*/
form ion-item {
  /*---background: transparent !important;
   /*--color: #737373;
   --border-width: 0;
   --inner-border-width: 0;
   --highlight-color-focused: transparent;
   --background-hover: transparent;
   --border-color: #d7d8da;
   --border-radius: 0;
   --border-style: solid;
   --padding-start: 0;
   font-size: 15px;*/
  /*--min-height: 2em;*/
  /* padding: 0;
    border-bottom: 1px dashed rgba(0,0,0,.1) !important;*/
}

form ion-label {
  white-space: normal;
}

form ion-item.bordered {
  border: 1px solid rgba(255, 255, 255, .5);
  margin: 5px 0;
  --border-color: transparent;
}

ion-item.bordered {
  border: 1px solid rgba(255, 255, 255, .5);
  margin: 5px 0;
  --border-color: transparent;
}

.formnoborder ion-item.bordered {
  border: none;
  /*border-top: 1px solid transparent !important;*/
  border-bottom: 1px solid rgba(255, 255, 255, .5) !important;
  /* border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;*/
  margin: 0;
  --min-height: 51px;
  --border-color: transparent;
}

.formnoborder.hr ion-item.bordered {
  /*  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;*/
  --min-height: 60px;
  --border-color: transparent;
}

.formnoborder ion-label {
  margin: 0 !important;

}

.formnoborder hr {
  height: 1px;
  margin: 5px;
  padding: 0;
  border-width: 0;
  background: transparent;
  box-sizing: content-box;
  overflow: hidden;
  content: "....";
  text-align: center;
}

.formnoborder ion-input {
  padding: 0 !important;
}

.formnoborder ion-label {
  font-size: 15px;
}

.formnoborder.hr ion-label {
  opacity: 0.8;
  font-style: italic;
}

.formnoborder ion-select ion-label {
  font-size: 17px !important;
}

.formnoborder app-text ion-label {
  font-size: 14px !important;
}

.formnoborder app-checkbox ion-label {
  font-size: 14px !important;
}

.formnoborder ion-label {
  font-style: italic !important;
}

.formnoborder ion-input {
  margin: 0 !important;
}

.formnoborder .item-label-stacked.sc-ion-input-md-h,
.formnoborder .item-label-stacked .sc-ion-input-md-h,
.formnoborder .item-label-floating.sc-ion-input-md-h,
.formnoborder .item-label-floating .sc-ion-input-md-h {
  --padding-top: 17px;
}

.formnoborder form ion-item.bordered ion-input input {
  margin: 0px !important;
}

.formnoborder .in-item {
  margin: 8px 0 4px 0 !important;
}

app-number ion-input {
  margin: 5px;
  padding: 5px !important;
}

form ion-item.noborder {
  border: none;
  margin: 2px 0;
  --min-height: 13px;
  --border-color: transparent;
}

form ion-item.noborder p {
  font-size: 16px;
  line-height: 20px;
  margin: 7px 0 7px 5px;
  color: #ea930e;
}

form ion-item.bordered ion-input input {
  margin: 5px;
}

.rca ion-item {
  --inner-padding-end: 0;
  --min-height: 2em;
}

.rca ion-grid {
  padding: 3px 0 0 0;
}

.rca ion-grid ion-row {
  height: 34px;
}

form h1 {
  font-size: 1.5em !important;
  padding: 20px !important;
  margin: 0 !important;
  line-height: 1.7em;
  border: none !important;
  color: #000;
  text-align: center;
  /*text-decoration: underline;*/
}

ion-label {
  padding-left: 1em;
  /*margin: 0 !important;*/
}

ion-label.nowhitespace {
  white-space: normal !important;
  /*padding: 15px;*/
}

ion-label.hide {
  display: none !important;
  width: 0 !important;
}

ion-label.hide+ion-select {
  width: 100%;
  max-width: 100%;
}

/*
  .login form ion-item {
    --background: #ffffff !important;
    --border-color: #bfbfbf;
    --border-radius:0;
    --border-style:dashed;
    --border-width:2px;

  }*/

ion-item ion-text p {
  padding: 0 1em;
}

/*
  ion-modal form .ion-item {
    background: #fff !important;
    //--border-radius: 1em;
    color:#000;
    highlight-color-focused:transparent;
    //  --border-color:#a78a48;
    border-color:transparent;
    border-radius:0;
    border-style:dashed;
    margin-bottom: 1em;
    padding: 0;
    display: block;
    position: relative;
  }*/
ion-modal form .ion-item .inner {
  /*padding: 1em;*/
}

.item-has-focus.label-floating.sc-ion-label-md-h,
.item-has-focus .label-floating.sc-ion-label-md-h,
.item-has-placeholder.label-floating.sc-ion-label-md-h,
.item-has-placeholder .label-floating.sc-ion-label-md-h,
.item-has-value.label-floating.sc-ion-label-md-h,
.item-has-value .label-floating.sc-ion-label-md-h {
  -webkit-transform: translateY(50%) scale(1);
  transform: translateY(50%) scale(1);
  color: black;
}

/*
 ion-modal form ion-label {
   font-weight: normal;
   text-transform: uppercase;
   color: #3e696d !important;
   color: #000 !important;
   white-space: normal  !important;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 ion-modal form app-checkbox ion-label {

   text-transform: uppercase;
   color: #000 !important;
   font-size: 11px !important;
   white-space: normal  !important;
   overflow: hidden;
   text-overflow: ellipsis;
 }
*/
/*
 ion-modal form app-checkbox-number ion-label {

   text-transform: uppercase;
   color: #000 !important;
   font-size: 11px !important;
   white-space: normal  !important;
   overflow: hidden;
   text-overflow: ellipsis;
 }*/
ion-popover {
  --width: 400px;
  max-width: 100% !important;
}

ion-popover.large {
  --width: 800px;
  max-width: 100% !important;
}

.days-box {
  padding: 0 !important;
}

.hr_1 {
  background: #ccc;
}

.hr_2 {
  background: #ababab;
}

.hr_3 {
  background: #ccc;
}

.days {
  width: calc((100% / 7) - 0px) !important;
  float: left;
  text-align: center;
  height: auto !important;
  margin-bottom: 1px !important;
}

button.days-btn {
  /*box-sizing: border-box;*/
  box-sizing: border-box;
  border-radius: 0 !important;
  border: 3px solid #fff !important;
  width: calc(100% - 1px) !important;
  height: 44px !important;
}

ion-calendar {
  padding: 0 !important;
}

ion-calendar .title {
  display: none;
}

/*
 .days-btn:before{
   content: " ";
   position: absolute;
   width: 100%;
   display: block;

   padding: 0;
   height: 50%;
   z-index:-1;
   outline: 0;
   top: 0;
   color: #fff;
 }
 .days-btn:after{
   content: " ";
   position: absolute;
   width: 100% !important;
   display: block;

   padding: 0;
   height:calc(50%);
   z-index:-1;
   outline: 0;
   top: calc(50%);
   color: #fff;
 }*/

.days-btn:before {
  content: " ";
  position: absolute;
  width: 50%;
  display: block;

  padding: 0;
  height: 100%;
  z-index: -1;
  outline: 0;
  top: 0;
  color: #fff;
}

.days-btn:after {
  content: " ";
  position: absolute;
  width: 50% !important;
  display: block;
  top: 0;
  padding: 0;
  height: calc(100%);
  z-index: -1;
  outline: 0;
  left: calc(50%);
  color: #fff;
}

button.days-btn p {
  width: 25px;
  padding: 5px 0;
  height: 25px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 25px;
  color: rgba(0, 0, 0, 0.7) !important;
  margin: 0 auto !important;
  line-height: 14px;
  font-size: 15px !important;
}

button.days-btn.on-selected {
  /*
    */
  border: 4px solid #306cff !important;
  background-color: transparent !important;
}

button.days-btn.on-selected:disabled,
button.days-btn.on-selected[disabled] {
  /*
    */
  border: 4px solid red !important;
  background-color: transparent !important;
}

.btn-validate-day {
  /*background: #fff  !important;*/
  color: #4a4a4a !important;
  border: 4px solid #306cff;
}

/*
 button.days-btn.on-selected p{

   background: rgba(255, 255, 255, 0.8);
   background: #fff  !important;
   color: #3880ff !important;
   border: 1px solid #3880ff;
 }
*/

button.days-btn.on-selected:before {
  /*  display: none;*/
}

button.days-btn.on-selected:after {
  /*  display: none;*/
}

app-select>ion-item>ion-label {
  font-size: 18px !important;
}

button.days-btn.alert_day {
  border: 3px solid #e91515 !important;
  /* color: #fff !important;*/
}

button.days-btn.am_fader:before {
  opacity: 0.35 !important;
}

button.days-btn.ap_fader:after {
  opacity: 0.35 !important;
}

.input_primary {
  background: #3a7be0 !important;
  color: #fff !important;
}

.input_danger {
  background: #e91515 !important;
  color: #fff !important;
}

.ma_MISSION:before {
  background: var(--skobeloff);
}

.ap_MISSION:after {
  background: var(--skobeloff);
}

.mission.color-1 {
  background: var(--mc1);
}

.ma_MISSION.ma_color-1:before {
  background: var(--mc1);
}

.ap_MISSION.ap_color-1:after {
  background: var(--mc1);
}

.mission.color-2 {
  background: var(--mc2);
}

.ma_MISSION.ma_color-2:before {
  background: var(--mc2);
}

.ap_MISSION.ap_color-2:after {
  background: var(--mc2);
}

.mission.color-3 {
  background: var(--mc3);
}

.ma_MISSION.ma_color-3:before {
  background: var(--mc3);
}

.ap_MISSION.ap_color-3:after {
  background: var(--mc3);
}

.mission.color-4 {
  background: var(--mc4);
}

.ma_MISSION.ma_color-4:before {
  background: var(--mc4);
}

.ap_MISSION.ap_color-4:after {
  background: var(--mc4);
}

.mission.color-5 {
  background: var(--mc5);
}

.ma_MISSION.ma_color-5:before {
  background: var(--mc5);
}

.ap_MISSION.ap_color-5:after {
  background: var(--mc5);
}

.mission.color-6 {
  background: var(--mc6);
}

.ma_MISSION.ma_color-6:before {
  background: var(--mc6);
}

.ap_MISSION.ap_color-6:after {
  background: var(--mc6);
}

.mission.color-7 {
  background: var(--mc7);
}

.ma_MISSION.ma_color-7:before {
  background: var(--mc7);
}

.ap_MISSION.ap_color-7:after {
  background: var(--mc7);
}

.mission.color-8 {
  background: var(--mc8);
}

.ma_MISSION.ma_color-8:before {
  background: var(--mc8);
}

.ap_MISSION.ap_color-8:after {
  background: var(--mc8);
}

.mission.color-9 {
  background: var(--mc9);
}

.ma_MISSION.ma_color-9:before {
  background: var(--mc9);
}

.ap_MISSION.ap_color-9:after {
  background: var(--mc9);
}

.mission.color-10 {
  background: var(--mc10);
}

.ma_MISSION.ma_color-10:before {
  background: var(--mc10);
}

.ap_MISSION.ap_color-10:after {
  background: var(--mc10);
}

.mission.color-11 {
  background: var(--mc11);
}

.ma_MISSION.ma_color-11:before {
  background: var(--mc11);
}

.ap_MISSION.ap_color-11:after {
  background: var(--mc11);
}

.mission.color-12 {
  background: var(--mc12);
}

.ma_MISSION.ma_color-12:before {
  background: var(--mc12);
}

.ap_MISSION.ap_color-12:after {
  background: var(--mc12);
}

.mission.color-13 {
  background: var(--mc13);
}

.ma_MISSION.ma_color-13:before {
  background: var(--mc13);
}

.ap_MISSION.ap_color-13:after {
  background: var(--mc13);
}

.mission.color-14 {
  background: var(--mc14);
}

.ma_MISSION.ma_color-14:before {
  background: var(--mc14);
}

.ap_MISSION.ap_color-14:after {
  background: var(--mc14);
}

.mission.color-15 {
  background: var(--mc15);
}

.ma_MISSION.ma_color-15:before {
  background: var(--mc15);
}

.ap_MISSION.ap_color-15:after {
  background: var(--mc15);
}

.mission.color-16 {
  background: var(--mc16);
}

.ma_MISSION.ma_color-16:before {
  background: var(--mc16);
}

.ap_MISSION.ap_color-16:after {
  background: var(--mc16);
}

.mission.color-17 {
  background: var(--mc17);
}

.ma_MISSION.ma_color-17:before {
  background: var(--mc17);
}

.ap_MISSION.ap_color-17:after {
  background: var(--mc17);
}

.mission.color-18 {
  background: var(--mc18);
}

.ma_MISSION.ma_color-18:before {
  background: var(--mc18);
}

.ap_MISSION.ap_color-18:after {
  background: var(--mc18);
}

.mission.color-19 {
  background: var(--mc19);
}

.ma_MISSION.ma_color-19:before {
  background: var(--mc19);
}

.ap_MISSION.ap_color-19:after {
  background: var(--mc19);
}

.mission.color-20 {
  background: var(--mc20);
}

.ma_MISSION.ma_color-20:before {
  background: var(--mc20);
}

.ap_MISSION.ap_color-20:after {
  background: var(--mc20);
}

.mission.color-21 {
  background: var(--mc21);
}

.ma_MISSION.ma_color-21:before {
  background: var(--mc21);
}

.ap_MISSION.ap_color-21:after {
  background: var(--mc21);
}

.mission.color-22 {
  background: var(--mc22);
}

.ma_MISSION.ma_color-22:before {
  background: var(--mc22);
}

.ap_MISSION.ap_color-22:after {
  background: var(--mc22);
}

.mission.color-23 {
  background: var(--mc23);
}

.ma_MISSION.ma_color-23:before {
  background: var(--mc23);
}

.ap_MISSION.ap_color-23:after {
  background: var(--mc23);
}

.mission.color-24 {
  background: var(--mc24);
}

.ma_MISSION.ma_color-24:before {
  background: var(--mc24);
}

.ap_MISSION.ap_color-24:after {
  background: var(--mc24);
}

.mission.color-25 {
  background: var(--mc25);
}

.ma_MISSION.ma_color-25:before {
  background: var(--mc25);
}

.ap_MISSION.ap_color-25:after {
  background: var(--mc25);
}

.mission.color-26 {
  background: var(--mc26);
}

.ma_MISSION.ma_color-26:before {
  background: var(--mc26);
}

.ap_MISSION.ap_color-26:after {
  background: var(--mc26);
}

.mission.color-27 {
  background: var(--mc27);
}

.ma_MISSION.ma_color-27:before {
  background: var(--mc27);
}

.ap_MISSION.ap_color-27:after {
  background: var(--mc27);
}

.mission.color-28 {
  background: var(--mc28);
}

.ma_MISSION.ma_color-28:before {
  background: var(--mc28);
}

.ap_MISSION.ap_color-28:after {
  background: var(--mc28);
}

.mission.color-29 {
  background: var(--mc29);
}

.ma_MISSION.ma_color-29:before {
  background: var(--mc29);
}

.ap_MISSION.ap_color-29:after {
  background: var(--mc29);
}

.mission-check {
  width: 0.8em;
  height: 0.8em;
  margin: 1px;
  display: block;
}

.mission.color-TELE{
  background : #a24ce0;
}
.ma_TELE:before{
  /*content : '⌂';*/
  text-align: left;
  padding-inline-start:0;
  font-size: 13px;
  background : #a24ce0 !important;

}
.ap_TELE:after {
  /*content : '⌂';*/
  text-align: left;
  padding-inline-start:0;
  font-size: 13px;
  background : #a24ce0 !important;
}

/*
 .ma_TELE.ap_TELE:after {
   content : ' ';
   text-align: left;
   padding-inline-start:0;
   font-size: 12px;
 }*/
.mission.color-PRES {
  background: #3369be;
}

.ma_PRES:before {
  background: #3369be;
}

.ap_PRES:after {
  background: #3369be;
}

.mission.color-PROSP {
  background: #61d27a;
}

.ma_PROSP:before {
  background: #61d27a;
}

.ap_PROSP:after {
  background: #61d27a;
}

.mission.color-CP {
  background: #c6f13f;
}

.ma_CP:before {
  background: #c6f13f;
}

.ap_CP:after {
  background: #c6f13f;
}

.mission.color-AM {
  background: #aedfff;
}

.ma_AM:before {
  background: #aedfff;
}

.ap_AM:after {
  background: #aedfff;
}

.mission.color-RTT {
  background: #ea930e;
}

.ma_RTT:before {
  background: #ea930e;
}

.ap_RTT:after {
  background: #ea930e;
}

.mission.color-FORM {
  background: #1da961;
}

.ma_FORM:before {
  background: #1da961;
}

.ap_FORM:after {
  background: #1da961;
}

.mission.color-DELEG {
  background: #ea930e;
}

.ma_DELEG:before {
  background: #ea930e;
}

.ap_DELEG:after {
  background: #ea930e;
}

.mission.color-ACSS {
  background: #f6f6f6;
}

.ma_ACSS:before {
  background: #f6f6f6;
}

.ap_ACSS:after {
  background: #f6f6f6;
}

.mission.color-BUG {
  background: #dfdede;
}

.ma_BUG:before {
  background: #d42929;
}

.ap_BUG:before {
  background: #d42929;
}

//déclare avoir travaillé tout le mois, mais n'a pas assez de solde pour un  temps complet
.ma_BUG_PAIE:before {
  background: #d94646;
}

.ap_BUG_PAIE:before {
  background: #d94646;
}

.journee:before {
  background: #3a7be0;
  content: " ";
  border-radius: 36px;
  position: absolute;
  width: 36px;
  display: block;
  padding: 0;
  height: 36px;
  z-index: -1;
  outline: 0;
  top: 0;
  color: #fff;
}

.journee p {
  color: #fff !important;
}

.my-custom-class .popover-content {
  height: 800px;
  max-height: 100vh;
  top: 20px !important;
}

/*
 ion-modal form ion-label p{
   font-weight: bold;
   text-transform: uppercase;
   color: #000 !important;
   font-size: 13px  !important;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 ion-modal ion-calendar ion-toolbar {
   --background: #ffffff !important;
   --color: #1d1d1d !important;
 }
 ion-modal form ion-item.item-has-focus,
 ion-modal form ion-item.ion-focusable:focus
 {
   --background: #ffffff !important;
   --border-color:#a78a48;
   --border-radius:0;
   --border-style:dashed;
   --border-width:2px;
}
*/

.closed {
  display: none;
}

a {
  outline: none;
}

a.button-icon {
  width: 1.7em;
  height: 1.7em;
  border: 1px solid #4242426e;
  color: #424242;
  display: block;
  border-radius: 1.7em;
  line-height: 1.7em;
  text-align: center;
  margin-top: -0.1em;
}

a.button-icon:hover {
  background: #ff0000;
  color: #fff;
}

form ion-item:before {
  /*background: #cdcdcd;*/
  color: #ffffff;
  content: " ";
  position: absolute;
  width: 2px;
  height: calc(100%);
  z-index: 2;
  top: 0;
}

form {
  /*border:1px solid  #cdcdcd;*/
}

app-table {
  position: relative;
}

app-file-upload {
  position: relative;
}

.prelative {
  position: relative;
}

ion-input input {
  padding-left: 1em !important;
  max-height: 2em !important;
}

ion-item ion-checkbox,
ion-checkbox.in-item {
  margin-left: 1em !important;
}

ion-item ion-calendar {
  padding-left: 1em !important;
  padding: 0 !important;
}

ion-textarea textarea {
  padding-left: 1em !important;
}

[data-message-color="primary"] {
  background: #3880ff;
  color: #ffffff;
  --background: #3880ff;
  --color: #fff;
}

[data-message-color="danger"]:before {
  background: #e91d3c;
  color: #ffffff;
  content: " ";
  position: absolute;
  width: 8px;
  height: 100%;
}

[data-message-color="success"]:before {
  background: #3ccd2c;
  color: #ffffff;
  content: " ";
  position: absolute;
  width: 8px;
  height: 100%;
}

form [data-message-color="danger"].bordered {
  /*border: 1px solid #c61552;*/
}

form [data-message-color="danger"]:before {
  background: transparent;
  color: #ffffff;
  content: " ";
  position: absolute;
  width: 4px;
}

form [data-message-color="success"]:before {
  background: #3ccd2c;
  color: #ffffff;
  content: " ";
  position: absolute;
  width: 4px;
}

.formnoborder [data-message-color="danger"]:before {
  background: darkred;
  color: #ffffff;
  content: " ";
  position: absolute;
  width: 4px;
}

.formnoborder form [data-message-color="danger"].bordered {
  border-color: darkred;
}

.formnoborder form [data-message-color="success"]:before {
  background: #55d757;
  color: #ffffff;
  content: " ";
  position: absolute;
  width: 4px;
}

h1 {
  margin-top: 18px;
  margin-left: 18px;
  color: #3e696d;
  font-size: 1.85em !important;
  border-bottom: 1px solid #3e696d;
}

h2 {}

h2.margin-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;

  font-size: 18px !important;
}

h2.info {
  margin-top: 18px;
  font-size: 14px !important;
  background: #6f888a;
  color: #fff;
  padding: 1em;
  font-weight: normal !important;
}

h2.alert {
  margin-top: 18px;
  font-size: 16px !important;
  background: #bf3e72;
  color: #fff;
  padding: 1em;
}

.calendar {
  height: max-content;
  width: max-content;
  min-width: 100%;
  background-color: var(--bg-main);
  font-size: 1em;
  position: relative;
  overflow: hidden;

  /* transform: scale(1.25); */
}

.light .calendar {
  box-shadow: var(--shadow);
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  color: var(--color-txt);
  /*  padding: 10px;*/
}

.calendar-body {
  /*   padding: 10px;*/
}

.calendar-week-day {
  height: 38px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: 600;
  gap: 0px;
  align-items: center;
  justify-content: center;
  color: #303030;
  background: #3e696d1f;
  border-bottom: 1px solid #f1f1f1;
}

.calendar-week-day div {
  width: 100%;
  /*max-width: 38px;*/
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: relative;
  color: var(--bg-second);
  outline: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0px;
}

.calendar-days>div {
  width: 100%;
  height: 38px;
  /* max-width: 38px;
    height: 38px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: relative;
  cursor: default;
  border-bottom: 1px solid #f1f1f1;
  animation: to-top 1s forwards;

  /* border-radius: 50%; */
}

.calendar-days .fade {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background: #f9f9f9;
  color: #1e1e1e;
  /* background-color: #1e2023;*/
}

.calendar-days .day-isOuvre {
  border-right: 1px solid #f1f1f1;
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

.calendar-days .day-isWeekEnd {
  /*
   border-right: 1px solid #FFFFFF;
   border-bottom: 1px solid #FFFFFF;
   background-color:#F1F1F1;
   color: #000;
    */
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  background-color: #f1f1f1;
  color: #b3b3b3;
  cursor: pointer;
}

.calendar-days .day-isFerie {
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  background-color: #dedede;
  color: #ffffff;
  cursor: pointer;
}

.calendar-days .range-date-click {
  background-color: #45979d;
  color: #fff;
  cursor: pointer;
}

.calendar-days .range-date-hover {
  background-color: #45979d;
  color: #fff;
  cursor: pointer;
}

.calendar-days b {
  z-index: 100;
  font-weight: normal;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  background: #f1f1f1;
  color: #3e3e3e;
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  text-align: center;
  padding: 4px 0;
}

.calendar-days>div>* {
  pointer-events: none;
}

.preventDefault * {
  pointer-events: none;
}

ion-item.active {
  border: 3px dashed #000;
}

.ion-item p {
  background: #d8f8d0;
  color: #000;
  padding: 0.5em;
}

.calendar-days [data-color="mission-0"] {
  background: #28ccdc;
  color: #ffffff;
  width: 100%;
  height: 100%;
}

[data-item-color="mission-0"] {
  background: #28ccdc;
  color: #ffffff;
}

[data-text-color="mission-0"] {
  color: #28ccdc;
}

[data-icon-color="mission-0"] {
  content: " ";
  width: 1em;
  height: 1em;
  margin-right: 1em;
  background: #28ccdc;
  color: #ffffff;
}

.calendar-days [data-color="mission-1"] {
  background: #add438;
  color: #ffffff;
  width: 100%;
  height: 100%;
}

[data-item-color="mission-1"] {
  background: #add438;
  color: #ffffff;
}

[data-text-color="mission-1"] {
  color: #add438;
}

[data-icon-color="mission-1"] {
  content: " ";
  width: 1em;
  height: 1em;
  margin-right: 1em;
  background: #add438;
  color: #ffffff;
}

.calendar-days [data-color="mission-2"] {
  background: #337e5e;
  color: #ffffff;
  width: 100%;
  height: 100%;
}

[data-item-color="mission-2"] {
  background: #337e5e;
  color: #ffffff;
}

[data-text-color="mission-2"] {
  color: #337e5e;
}

[data-icon-color="mission-2"] {
  content: " ";
  width: 1em;
  height: 1em;
  margin-right: 1em;
  background: #337e5e;
  color: #ffffff;
}

.calendar-days [data-color="mission-3"] {
  background: #3721b3;
  color: #ffffff;
  width: 100%;
  height: 100%;
}

[data-item-color="mission-3"] {
  background: #3721b3;
  color: #ffffff;
}

[data-text-color="mission-3"] {
  color: #3721b3;
}

[data-icon-color="mission-3"] {
  content: " ";
  width: 1em;
  height: 1em;
  margin-right: 1em;
  background: #3721b3;
  color: #ffffff;
}

.calendar-days [data-color="prosp"] {
  background: #ab6743;
  color: #ffffff;
  width: 100%;
  height: 100%;
}

[data-item-color="prosp"] {
  background: #ab6743;
  color: #ffffff;
}

[data-text-color="prosp"] {
  color: #ab6743;
}

[data-icon-color="prosp"] {
  content: " ";
  width: 1em;
  height: 1em;
  margin-right: 1em;
  background: #ab6743;
  color: #ffffff;
}

.calendar-days [data-color="am"] {
  background: #cf2883;
  color: #ffffff;
  width: 100%;
  height: 100%;
}

[data-item-color="am"] {
  background: #cf2883;
  color: #ffffff;
}

[data-text-color="am"] {
  color: #cf2883;
}

[data-icon-color="am"] {
  content: " ";
  width: 1em;
  height: 1em;
  margin-right: 1em;
  background: #cf2883;
  color: #ffffff;
}

/*
 .am:not(.on-selected)
 {
   border :1px #cf2883  dashed !important;

 }*/
.am:not(.on-selected) p {
  /* color: #ffffff !important;*/
}

.calendar-days [data-color="cp"] {
  background: #d2790b;
  color: #ffffff;
  width: 100%;
  height: 100%;
}

[data-item-color="cp"] {
  background: #d2790b;
  color: #ffffff;
}

[data-text-color="cp"] {
  color: #d2790b;
}

[data-icon-color="cp"] {
  content: " ";
  width: 1em;
  height: 1em;
  margin-right: 1em;
  background: #d2790b;
  color: #ffffff;
}

.calendar-days [data-color="rtt"] {
  background: #ec6409;
  color: #ffffff;
  width: 100%;
  height: 100%;
}

[data-item-color="rtt"] {
  background: #ec6409;
  color: #ffffff;
}

[data-text-color="rtt"] {
  color: #ec6409;
}

[data-icon-color="rtt"] {
  content: " ";
  width: 1em;
  height: 1em;
  margin-right: 1em;
  background: #ec6409;
  color: #ffffff;
}

.ab-color {
  background: #262626;
}

.calendar-days>[data-disabled="true"] {
  cursor: not-allowed !important;
  pointer-events: none;
}

.calendar-days>[data-disabled="true"]:after {
  content: " ";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
}

.calendar-days>[data-clicked="true"]:after {
  content: " ";
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  left: 0;
  top: 0;
  z-index: 200;
  border: 3px dashed #020202;
  position: absolute;
}

.outline_reset {
  outline: none;
  pointer-events: none;
}

.bg_white {
  background: #fff;
}

.table-item {
  display: table;
  width: 100%;
  border-spacing: 0;
}

.table-item>div {
  display: table-cell;
  vertical-align: top;
  text-align: left;
  padding: 1em;
}

.week-toolbar {
  background: #f1f1f1;
}

.table-input {
  display: table;
  width: calc(100% - 5px);
  border-spacing: 2px;
  table-layout: fixed;
  margin: 0 0 0 5px;
}

.table-input {
  background: #f5f6f9;
}

ion-item {
  --border-color: rgba(0, 0, 0, 0.13);
}

@media (prefers-color-scheme: dark) {
  ion-item {
    --border-color: rgba(255, 255, 255, 0.13);
  }
}

ion-item.separator {
  --min-height: 1px;
  --background: #92949c;
  --background-activated: transparent;
  --background-focused: currentColor;
  --background-hover: currentColor;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0.12;
  --background-hover-opacity: 0.04;
  --transition: opacity 15ms linear, background-color 15ms linear;
  --padding-start: 0;
  --color: var(--ion-item-color, var(--ion-text-color, #000));
  --border-color: var(--ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  --inner-padding-end: 0;
  --inner-border-width: 0;
  --highlight-height: 0;
  --highlight-color-focused: var(--ion-color-primary, #3880ff);
  --highlight-color-valid: var(--ion-color-success, #2dd36f);
  --highlight-color-invalid: var(--ion-color-danger, #eb445a);
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  text-align: center;
  overflow: hidden;

  p {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    line-height: 24px;
  }
}

ion-fab-button[color="transparent"] {
  background: transparent !important;
}

.badge_money {
  min-width: 120px;
  background: rgba(255, 255, 255, 0.7);

  padding: 6px 10px;
  height: 2.2em;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);

  span {
    height: 1em;
    line-height: 1.3em;
    color: inherit;
    display: inline-block;
    float: right;
  }
}

.badge_money.plus:before {
  content: "+";
  font-weight: bold;
  display: inline-block;
}

.badge_money.moins:before {
  content: "-";

  font-weight: bold;
  display: inline-block;
}

.badge_fichier {
  min-width: 30px;
  background: rgba(255, 255, 255, 0.7);

  padding: 6px 10px;
  height: 2.2em;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  span {
    height: 1em;
    line-height: 1.3em;
    color: inherit;
    display: inline-block;
    float: right;
  }
}

.badge_jours {
  min-width: 55px;
  background: rgba(255, 255, 255, 0.7);

  padding: 6px 10px;
  height: 2.2em;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  span {
    height: 1em;
    line-height: 1.3em;
    color: inherit;
    display: inline-block;
    float: right;
  }
}

.form_tpl ion-label {
  color: #3e696d;
}

.form_tpl ion-item.bordered {
  border-top: 0px solid transparent !important;
  border-bottom: 1px dashed #d5d5d5 !important;
  border-left: 0px solid transparent !important;
  border-right: 0px solid transparent !important;
  margin: 0;
  --min-height: 44px;
  --border-color: transparent;
}

.form_tpl app-text ion-item.bordered {
  --min-height: 55px;
}

.form_tpl.hr ion-item.bordered {
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid #ccc !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  --min-height: 60px;
  --border-color: transparent;
}

.form_tpl ion-label {
  margin: 0 !important;
}

.form_tpl hr {
  height: 1px;
  margin: 5px;
  padding: 0;
  border-width: 0;
  background: #d3d3d3;
  box-sizing: content-box;
  overflow: hidden;
  content: "....";
  text-align: center;
}

.form_tpl ion-input {
  padding: 0 !important;
}

.form_tpl app-select>ion-item>ion-label {
  font-size: 16px !important;
  font-style: italic;
}

.form_tpl app-text>ion-item>ion-label {
  font-size: 12px !important;
  font-style: italic;
}

.form_tpl app-checkbox>ion-item>ion-label {
  font-size: 13px !important;
  font-style: italic;
}

.form_tpl app-boolean>ion-item>ion-label {
  font-size: 13px !important;
  font-style: italic;
}

.form_tpl app-date>ion-item>ion-label {
  font-size: 13px !important;
  font-style: italic;
}

.form_tpl app-number>ion-item>ion-label {
  font-size: 13px !important;
  font-style: italic;
}

.form_tpl app-textarea>ion-item>ion-label {
  font-size: 13px !important;
  font-style: italic;
}

.form_tpl ion-input {
  margin: 0 !important;
}

.form_tpl ion-input input {
  font-weight: bolder !important;
}

.form_tpl ion-select select {
  font-weight: bolder !important;
}

.form_tpl .item-label-stacked.sc-ion-input-md-h,
.form_tpl .item-label-stacked .sc-ion-input-md-h,
.form_tpl .item-label-floating.sc-ion-input-md-h,
.form_tpl .item-label-floating .sc-ion-input-md-h {
  --padding-top: 17px;
}

.form_tpl form ion-item.bordered ion-input input {
  margin: 0px !important;
}

.form_tpl .in-item {
  /*margin: 8px 0 4px 0 !important;*/
  font-weight: bold;
}

.form_tpl [data-message-color]:before {
  background: #858585;
  color: #ffffff;
  content: " ";
  position: absolute;
  width: 4px;
}

.form_tpl form [data-message-color].bordered {
  border-color: #858585;
}

.form_tpl [data-message-color="danger"]:before {
  background: #e21111;
  /*   background: darkred; */
  color: #ffffff;
  content: " ";
  position: absolute;
  width: 4px;
}

.form_tpl form [data-message-color="danger"].bordered {
  /* background: #e21111;*/
}

.form_tpl [data-message-color="success"]:before {
  background: #36cb38;
  color: #ffffff;
  content: " ";
  position: absolute;
  width: 4px;
}

.form_tpl form [data-message-color="success"].bordered {
  /* border-color: #36cb38;*/
}

ion-item[color="medium"] .badge_money,
ion-item[color="medium"] .badge_fichier,
ion-item[color="medium"] .badge_jour {
  background: rgba(0, 0, 0, 0.2);
}

ion-item[color="secondary"] .badge_money,
ion-item[color="secondary"] .badge_fichier,
ion-item[color="secondary"] .badge_jour {
  background: rgba(0, 0, 0, 0.2);
}

ion-item.borderBottom {
  border-bottom: 1px solid #f1f1f1 !important;
}

.icon_size_1 {
  font-size: 14px !important;
  width: 1em;
  height: 1em;
  user-select: none;
}

.icon_size_2 {
  font-size: 12px !important;
  width: 0.8em;
  height: 0.8em;
}

.ml15 {
  margin-left: 15px;
}

ion-modal ion-content {
  --background: #232c56 !important;
}

ion-modal ion-content.white {
  --background: #fff !important;
}

ion-menu ion-content {
  --background: #ffffff !important;
}

.table-input>div {
  display: table-cell;
  vertical-align: top;
  text-align: center;
  padding: 0 !important;
  text-align: left;
}

.table-input>div.input {
  border: 1px dashed #c7c7c7;
}

.table-input.quantite_error>div.quantite {
  border-color: red;
  border-width: 3px;
}

.table-input.client_taux_error>div.client_taux {
  border-width: 3px;
}

.table-input>div.total {
  border-type: solid;
  background: #aeaeae;
  cursor: not-allowed;
  color: #fff;
}

/*
 .table-input.total_error  > div.total{
   border-color: red;

 }
*/

.table-input>div.quantite {
  width: 120px;
}

.table-input>div.taux {
  width: 120px;
}

.table-input>div.debut {
  width: 120px;
}

.table-input>div.fin {
  width: 120px;
}

app-form {
  display: block;
  width: 100%;
  min-height: 10px;
}

ion-slides {
  /* height: 300px;*/
}

.formnoborder app-form ion-row,
.formnoborder app-form ion-col {
  padding: 0;
}

.table-input>div>input {
  width: 100%;
  border: none;
  background: transparent;
}

.table-input>div.label-input {
  border: 1px solid #dedcdc;
}

.table-input>div>label {
  display: table;
  width: 100%;
  /* background:#f6f3ec;*/
}

.table-input>div>label>* {
  display: table-cell;
  border: none;
  vertical-align: top;
  padding: 5px;
}

.table-input>div>label>span {
  width: 30%;
  overflow: hidden;
  word-wrap: normal;
}

.table-input>div>label>span+* {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
.table-input>div>input::-webkit-outer-spin-button,
.table-input>div>input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.table-input>div>input[type="number"] {
  -moz-appearance: textfield;
}

.table-input>div>select {
  width: 100%;
  border: none;
  background: transparent;
}

.table-input>div>input:focus-visible {
  outline: none;
  /*
     border: 3px dashed #a78a48;
    */
}

.table-input>div>select:focus-visible {
  outline: none;
  /*
     border: 3px dashed #a78a48;
    */
}

.inner-table td {
  padding: 0.4rem;
}

.inner-table td.euro {
  width: 9em;
}

.table-list-item {
  display: table;
  width: 100%;
  border-spacing: 3px;
}

.table-list-item.no-spacing {
  border-spacing: 0;
}

.table-list-item>div {
  display: table-cell;
  vertical-align: top;
  text-align: left;
  padding: 3px;
}

.table-list-item.no-spacing>div {
  padding: 0px;
}

.table-list-item>div ion-badge {
  font-weight: normal !important;
  padding: 6px;
  border-radius: 0;
}

@media (max-width: 600px) {
  .table-item {
    display: block;
    width: 100%;
  }

  .table-item>div {
    display: block;
    padding: 1em 0;
    margin-bottom: 7px;
    width: 100% !important;
    vertical-align: top;
  }

  .table-input {
    display: block;
    width: 100%;
  }

  .table-input>div {
    display: block;
    padding: 1em 0;
    margin-bottom: 7px;
  }

  .table-list-item>div {
    vertical-align: top;
  }
}

.table-item>div.border {
  border: 1px dashed #c7c7c7;
}

.table {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/*
 .table > span {
   display: table-row;
   width: 100%;
   height: 100%;
   position: absolute;
   top:0;
 }

 .table[data-size="2"] > span {
   height: 50%;
 }
 .table[data-size="2"] > span:nth-child(1) {
   top: 0;
 }
 .table[data-size="2"] > span:nth-child(2) {
   top: 50%;
 }


 .table[data-size="3"] > span {
   height: 33%;
 }
 .table[data-size="3"] > span:nth-child(1) {
   top: 0;
 }
 .table[data-size="3"] > span:nth-child(2) {
   top: 33%;
 }
 .table[data-size="3"] > span:nth-child(3) {
   top: 66%;
 }
*/
.table>span {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.table>span[data-quantite="J"] {
  height: 100%;
}

.table>span[data-quantite="Ma"] {
  height: 100%;
}

.table>span[data-quantite="Ma"]::before {
  content: " ";
  width: 100%;
  height: 50%;
  top: 50%;
  position: absolute;
  background: linear-gradient(45deg,
      #f1f1f1 12.5%,
      #fff 12.5%,
      #fff 37.5%,
      #f1f1f1 37.5%,
      #f1f1f1 62.5%,
      #fff 62.5%,
      #fff 87.5%,
      #f1f1f1 87.5%);
  background-size: 50%;
  background-position: 0;
  z-index: 1;
}

.table>span[data-quantite="Ap"] {
  height: 100%;
  top: 0;
}

.table>span[data-quantite="Ap"]::before {
  content: " ";
  width: 100%;
  height: 50%;
  top: 0;
  position: absolute;
  background: linear-gradient(45deg,
      #f1f1f1 12.5%,
      #fff 12.5%,
      #fff 37.5%,
      #f1f1f1 37.5%,
      #f1f1f1 62.5%,
      #fff 62.5%,
      #fff 87.5%,
      #f1f1f1 87.5%);
  background-size: 50%;
  background-position: 0;
  z-index: 1;
}

.table>span[data-quantite="F"] {
  height: 100%;
  top: 0%;
}

.table>span[data-quantite="F"]::before {
  content: "F";
  right: 1px;
  top: 1px;
  font-size: 9px;
  width: 12px;
  position: absolute;
  text-align: center;
  background: #000;
  border-radius: 6px;
}

.table[data-size="2"]>span {
  width: 50%;
}

.table[data-size="2"]>span:nth-child(1) {
  left: 0;
}

.table[data-size="2"]>span:nth-child(2) {
  left: 50%;
}

.table[data-size="3"]>span {
  width: 33.3333%;
}

.table[data-size="3"]>span:nth-child(1) {
  left: 0;
}

.table[data-size="3"]>span:nth-child(2) {
  left: 33.3333%;
}

.table[data-size="3"]>span:nth-child(3) {
  left: 66.6666%;
}

.ion-page .ion-page-center {
  width: 100%;
  min-height: 90%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ion-page-center ion-card {
  height: auto;
  width: 400px;
}

.accordion>ion-accordion>ion-list {}

.accordion {
  background: red;
}

ion-searchbar.custom {
  --box-shadow: none;
}

/*
 ion-segment-button {
   --background-checked: var(--ion-color-primary);
   --color-checked: var(--ion-color-primary-contrast);
   --indicator-color: transparent;
 }
*/

/*
 .another-day::after{

 }

 [data-class="mission-0"],
 {
   color: --dark-jungle-green;
 }
 .mission-0,
 .another-day-mission-0::after{
   background: $dark-jungle-green !important;
 }
 .another-day-mission-0::after{
   left: 0px !important;
 }

 [data-class="mission-1"]{
   color: $pine-green;
 }
 .mission-1,
 .another-day-mission-1::after{
   background:$pine-green !important;
 }
 .another-day-mission-1::after{
   left: 11px !important;
 }

 [data-class="mission-2"]{
   color:  $light-sea-green;
 }
 .mission-2,
 .another-day-mission-2::after{
   background: $light-sea-green !important;
 }

 [data-class="mission-3"]{
   color: $turquoise;
 }
 .mission-3,
 .another-day-mission-3::after{
   background: $turquoise !important;
 }

 [data-class="mission-4"]{
   color:  $sky-blue;
 }
 .mission-4,
 .another-day-mission-4::after{
   background: $sky-blue !important;
 }

 [data-class="mission-5"]{
   color: $dark-jungle-green;
 }
 .mission-5,
 .another-day-mission-5::after{
   background: $dark-jungle-green !important;
 }
*/
/*

#main{
    height: 100%;
    width: 100%;

}
ion-header{
    background: #fff;
}

ion-button{
    text-transform: uppercase;
}
[data-widget = "logout"]{
    cursor:pointer;
}
.breadcrumb{
    padding:1rem 1rem 1rem 1rem;display:block;
}
.breadcrumb a{
   text-decoration: underline;
   cursor: pointer;
}
 h1.brand{
    border-bottom:1px solid #ccc;border-top:1px solid #ccc;padding:.5rem;background:#fff;margin:0;
 }
.ion-page .ion-page-center {
    width: 100%;
    min-height: 90%;

    display: flex;
    align-items: center;
    justify-content: center;
}
.ion-page-center ion-card  {
    height: auto;
    width: 400px;

}
.ion-page-center ion-spinnerion-spinner  {
    height: 5em;
    width: 5em;

}
.ion-page .left_side{
    position: fixed;
    right: 500px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    width: calc(100vw - 500px);
}
.ion-page .right_side{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #fff;
    width: 500px;
    max-width: 100vw;
    min-height: 100%;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2rem;
    padding-top: 3vh;
    padding-bottom: 1rem;
    z-index: 1;
    margin-left: auto;
}
.ion-page .center_side{

    width: 500px;
}

p{
    display: block;
}
.text-center{
    text-align: center;
}

.t1 ion-card-header{
    height:4em;
    width:100%;
    display: table;
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #ccc;
    font-size: 1.2em;
    padding:.1em;
}
.t1 ion-card-header ion-card-title{

    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    font-size: inherit;
}

.t1 ion-footer {

}

.t1 ion-footer ion-button{

}

.t1 ion-footer ion-button button{

}

ion-card-title{
    color:#3e696d;
    text-transform: uppercase;
    font-weight: bolder;
}
ion-card-title.brand{
    color:#3E696D;
    text-transform: uppercase;
    font-weight: bolder;
}
.t1 .circle{
 width:100px;
 height:100px;
 background: #F1F1F1;
 border-radius:140px;
margin:1em auto;
}
.t1 ion-img {
object-fit: scale-down;

height: 10rem;
margin: 0 auto;
padding: 1rem;
}
.t1.message ion-img {
object-fit: scale-down;

height: 7rem;
margin: 0 auto;
padding: 1rem 1rem 0 1rem;
}
.t1.message h1 {
 text-align: center;
height: 3rem;
margin: 0 auto;
padding: 1rem;
}


@media (max-width: 700px) {
h1.brand{
  text-align: center;
}
ion-grid-columns : calc(2 * 12);
}
@media (min-width: 1100px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1300px) {
}
@media (min-width: 1600px) {
}
@media (min-width: 2000px) {
}
*/
.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 200;
  overflow-x: hidden;
  overflow-y: scroll;
  left: 0;
  top: 0;
  display: table;
}

.mask-checkbox {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2) !important;
  z-index: 200;
  overflow-x: hidden;
  overflow-y: scroll;
  left: 0;
  top: 0;
  display: table;
}

.mask-nav {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0) !important;
  z-index: 200;
  overflow-x: hidden;
  overflow-y: scroll;
  left: 0;
  top: 0;
  display: table;
}

.mask.transparent {
  background-color: rgba(0, 0, 0, 0) !important;
}

.mask:before {
  content: "";
  display: table-row;
  width: 100vw;
  height: calc(100vh / 10);
  z-index: 200;
  left: 0;
  top: 0;
}

.mask:after {
  content: "";
  display: table-row;
  width: 100vw;
  height: calc(100vh / 10);
  z-index: 200;
  left: 0;
  top: 0;
}

.pre-progress {
  /* margin: 200px auto; */
  width: 100px;
  height: 50px;
  position: fixed;
  top: calc(100vh / 2);
  left: calc(100vw / 2);
  z-index: 100000;
}

.pre-progress:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.1);
}

.pre-loader {
  /* margin: 200px auto; */
  width: 100px;
  height: 50px;
  position: fixed;
  top: calc(100vh / 2);
  left: calc(100vw / 2);
  z-index: 5000;
}

.pre-loader:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
}

.pre-loader>div {
  background-color: #fff;
  height: 100%;
  width: 10px;
  display: inline-block;
}

.pre-loader>div {
  background-color: #fff;
  height: 100%;
  width: 10px;
  display: inline-block;
  -webkit-animation: heightChange 1s infinite ease-in-out;
  animation: heightChange 1s infinite ease-in-out;
}

.pre-loader .box2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.pre-loader .box3 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.pre-loader .box4 {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.pre-loader .box5 {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

@keyframes myMove {
  0% {
    top: 0px;
  }

  25% {
    top: 200px;
  }

  50% {
    top: 100px;
  }

  75% {
    top: 200px;
  }

  100% {
    top: 0px;
  }
}

@-webkit-keyframes heightChange {

  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes heightChange {

  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }
}

@media (prefers-color-scheme: dark) {
  ion-item {
    color: #a7a7a7;
  }

  .item.sc-ion-label-md-h,
  .item .sc-ion-label-md-h {
    /* --color:  #a7a7a7;*/
    display: block;
  }

  .item-has-focus.label-floating.sc-ion-label-md-h,
  .item-has-focus .label-floating.sc-ion-label-md-h,
  .item-has-placeholder.label-floating.sc-ion-label-md-h,
  .item-has-placeholder .label-floating.sc-ion-label-md-h,
  .item-has-value.label-floating.sc-ion-label-md-h,
  .item-has-value .label-floating.sc-ion-label-md-h {
    transform: translateY(50%) scale(1);
    color: #e5e5e5;
  }

  .badge_jours span {
    height: 1em;
    line-height: 1.3em;
    color: #000;
    display: inline-block;
    float: right;
  }

  .badge_money span {
    height: 1em;
    line-height: 1.3em;
    color: #000;
    display: inline-block;
    float: right;
  }

  .badge_fichier span {
    height: 1em;
    line-height: 1.3em;
    color: #000;
    display: inline-block;
    float: right;
  }
}

.ck-editor__editable_inline {
  min-height: 300px;
}

///////////////////////// COMMENTAIRE ELSA /////////////////////////
.icon_size_edit {
  font-size: 12px !important;
  width: 1.5em;
  height: 1.5em;
}

.image-block {
  width: 250px;
  height: 250px;
  position: relative;
  float: left;
  margin: 15px 0 15px 15px;
  overflow: hidden;
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: 0px 0px 12px -4px #6e6e6e;
  box-shadow: 0px 0px 12px -4px #6e6e6e;
  border-radius: 3px;
}

.image-embed {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  z-index: 1;
}

.image-item {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.image-form.closed {
  display: none;
}

.image-form.open {
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  z-index: 3;
}

app-gallery ion-toolbar,
app-gallery ion-toolbar * {}

app-gallery ion-toolbar {}

.image-toolbar-top {
  --min-height: 30px;
  z-index: 2;
}

.image-toolbar-top * {
  font-size: 13px;
}

.image-toolbar-bottom {
  --min-height: 42px;
  z-index: 2;
  margin-top: -42px;
  font-size: 12px;
  --background: rgba(255, 255, 255, 0) !important;
}

.image-toolbar-bottom * {
  font-size: 13px;
}

app-gallery ion-fab-button {
  margin: 0px;
  width: 30px;
  height: 30px;
}

app-ag-grid-button ion-fab-button {
  padding: 0;
  margin: 0;
  height: 15px;
  width: 15px;
  display: inherit;
}

app-ag-grid-button-icon ion-fab-button {
  padding: 0;
  margin: 0;
  height: 15px;
  width: 15px;
  display: inherit;
}

app-ag-grid-button-param ion-fab-button {
  padding: 0;
  margin: 0;
  height: 15px;
  width: 15px;
  display: inherit;
}

app-ag-grid-button-action ion-button {
  padding: 0;
  margin: 4px;
  height: 30px;
  display: block;
}

app-ag-grid-button-circle ion-fab-button {
  padding: 0;
  margin: 5px;
  height: 30px;
  width: 30px;
  display: block;
}

app-ag-grid-button-circle ion-fab-button ion-label {
  padding: 0;
}

app-ag-grid-missions-icon ion-fab-button {
  padding: 3px;
  margin: 5px 0;
  height: 30px;
  width: 30px;
  display: inline-block;
}

app-ag-grid-button-links ion-fab-button {
  padding: 3px;
  margin: 5px 0;
  height: 30px;
  width: 30px;
  display: inline-block;
}

app-ag-grid-button ion-button {
  padding: 0px;
  margin: 9px 0;
  height: 24px;
  border: none !important;
  color: #2a2a2a;
  /* width: 15px; */
  display: block;
}

.right_pannel {
  position: absolute;
  left: 100vw;
  top: 0;
  width: 100vw;
  height: 100%;
  position: absolute;
  z-index: 1000;
  background: rgba(0, 0, 0, .4);
  overflow: hidden;
  /*
  overflow-y: auto;
  -webkit-transform: translateX(calc(0));
  transform: translateX(calc(0));
  -webkit-transition: transform 0.2s ease-in-out;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;*/
}

.right_pannel.aside_open.open {
  left: 0;
}

.right_pannel>ion-content {
  left: 100vw;
  width: 450px;
  height: 100%;
  z-index: 10;
  display: block;
  overflow: hidden;
  position: absolute;
  -webkit-transform: translateX(calc(0));
  transform: translateX(calc(0));
  -webkit-transition: transform 0.2s ease-in-out;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.right_pannel.aside_open.open>ion-content {
  -webkit-transform: translateX(calc(-450px));
  transform: translateX(calc(-450px));
}

.right_pannel.medium-xl>ion-content {
  left: 100vw;
  width: 75vw;
  height: 100%;
  z-index: 10;
  display: block;
  overflow: hidden;
  position: absolute;
  -webkit-transform: translateX(calc(0));
  transform: translateX(calc(0));
  -webkit-transition: transform 0.2s ease-in-out;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.right_pannel.medium-xl.aside_open.open>ion-content {
  -webkit-transform: translateX(calc(-75vw));
  transform: translateX(calc(-75vw));
}

.right_pannel.medium>ion-content {
  left: 100vw;
  width: 650px;
  height: 100%;
  z-index: 10;
  display: block;
  overflow: hidden;
  position: absolute;
  -webkit-transform: translateX(calc(0));
  transform: translateX(calc(0));
  -webkit-transition: transform 0.2s ease-in-out;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.right_pannel.medium.aside_open.open>ion-content {
  -webkit-transform: translateX(calc(-650px));
  transform: translateX(calc(-650px));
}

.right_pannel.large>ion-content {
  left: 100vw;
  width: 100vw;
  height: 100%;
  z-index: 10;
  display: block;
  overflow: hidden;
  position: absolute;
  -webkit-transform: translateX(calc(0));
  transform: translateX(calc(0));
  -webkit-transition: transform 0.2s ease-in-out;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.right_pannel.large.aside_open.open>ion-content {
  -webkit-transform: translateX(calc(-100vw));
  transform: translateX(calc(-100vw));
}


/*
.right_pannel.open {
  -webkit-transform: translateX(calc(-100vw));
  transform: translateX(calc(-100vw));
}

.right_pannel.size_md.aside_open:before{
  width: calc(100vw - 450px);
  height:100%;
  background: rgba(0,0,0,.4);
  content: " ";
  position: fixed;
  z-index: 6;
  display: block;
  right:450px;
}
.right_pannel.size_md.open{
  width: 450px;
  -webkit-transform: translateX(calc(-450px));
  transform: translateX(calc(-450px));
  overflow: initial;
  overflow-y:initial;
}*/
ion-toolbar.mini {
  --min-height: 32px;
}

iframe {
  border-width: 0;
  border: none;
}

ion-toolbar.mini ion-fab-button {
  margin: 4px;
  margin-left: 15px;
  width: 15px;
  height: 15px;
}

ion-toolbar.mini ion-fab-button.fab-button-small {
  margin: 4px;
  width: 20px;
  height: 20px;
}

ion-toolbar.mini ion-button {
  --border-radius: 0;
  margin-left: unset;
  margin-right: unset;
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
}

ion-list.mini ion-item {
  --padding-start: 0;
  font-size: 11px;
}

ion-list.mini_2 ion-item {
  --padding-start: 0;
  font-size: 12px;
}

ion-list.mini .fab-button-small {
  margin: 4px;
  margin-left: 15px;
  width: 15px;
  height: 15px;
}

ion-list.mini .fab-button-small {
  margin: 4px;
  margin-left: 15px;
  width: 15px;
  height: 15px;
}

ion-list.mini_2 .fab-button-small {
  margin: 4px;
  margin-left: 15px;
  width: 15px;
  height: 15px;
}

app-ag-grid-input {
  width: 100%;
  height: 100%;
  display: flex;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;*/
}

app-ag-grid-input ion-input input {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: flex !important;
  /*color: #2a2a2a !important;*/
}

app-ag-grid-select ion-select {
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.spinner-cell {
  position: absolute;
  top: 0;
  margin-top: 2px;
  margin-left: calc(100% - 50px);
}

app-ag-grid-select [disabled="true"],
app-ag-grid-select [disabled="true"] * {
  opacity: 1 !important;
}

app-ag-grid-input [disabled="true"],
app-ag-grid-input [disabled="true"] * {
  opacity: 1 !important;
}

.app-page {
  display: flex;
  flex-direction: row;
  /*column-count: 3;*/
}

.app-page>div:nth-child(2) {
  width: 80vw;
}

.app-page>div {
  max-width: 90vw;
}

.app-page>div:nth-child(3) {
  width: 19vw;
}

.app-page-inner {
  height: calc(100vh - 125px);
  display: flex;
  flex-direction: row;
  width: 100%;
  /*column-count: 3;*/
}

.app-page-inner>div {
  /*max-width: 50%;*/
  /*width: 50%;*/
  background: #fff;
}

@media (max-width: 1100px) {
  .app-page>div:nth-child(3) {
    display: none;

    width: 0;
  }

  .app-page>div:nth-child(2) {
    width: 100vw;
  }

  .app-page>div {
    max-width: 100vw;
  }
}

[popover]:not(:popover-open):not(dialog[open]) {
  display: contents !important;
}

circle-button {
  width: 30px;
  height: 30px;
  background-color: #eeeeee;
  border-radius: 30px;
  font-size: 12px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

circle-button[size="small"] {
  width: 22px;
  height: 22px;
  font-size: 11px;
}

diamond-button {
  width: 30px;
  height: 30px;
  background-color: #eeeeee;
  transform: rotate(45deg);
  font-size: 12px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

diamond-button[size="small"] {
  width: 22px;
  height: 22px;
  font-size: 11px;
}

square-button {
  width: 30px;
  height: 30px;
  background-color: #eeeeee;
  font-size: 12px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

square-button[size="small"] {
  width: 22px;
  height: 22px;
  font-size: 11px;
}

app-fab-button {
  width: 56px;
  height: 56px;
  font-size: 24px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  /*margin: 0 0 0 14px;*/
  text-transform: uppercase;
  cursor: pointer;
}

app-fab-button.active:before {
  border: 3px solid rgba(0, 0, 0, .8);
}

/*

app-fab-button.active:before{
  background:transparent;
  color:#fff;
}*/
app-fab-button+app-fab-button {
  padding-left: 0;
}

app-fab-button+label {
  padding-left: 0;
}

app-fab-button:before {
  width: 42px;
  height: 42px;
  background-color: #f3f3f3;
  content: " ";
  position: absolute;
  z-index: 0;

  border: 1px solid rgba(255, 255, 255, .8);
  cursor: pointer;
}

ion-item app-fab-button:before {
  border: 1px solid rgba(255, 255, 255, .8);
}

ion-list app-fab-button {
  margin-left: 10px;
}

ion-list app-fab-button:before {
  width: 36px;
  height: 36px;
  background-color: #f3f3f3;
  content: " ";
  position: absolute;
  z-index: 0;
  cursor: pointer;
}

app-fab-button.rectangle {
  width: 80px;
  position: relative;
  background-color: #e0e0e0;
  color: #2f2f2f;
}

app-fab-button.rectangle>small {
  width: 80px;
  position: relative;

  color: #2f2f2f;
}

app-fab-button.rectangle:before {
  position: relative;
  background: transparent !important;
}


app-fab-button>small {
  position: absolute;
  font-size: 11px;
}

app-fab-button.danger {
  color: #fff;
}

app-fab-button.danger:before {
  background: red;
}

app-fab-button.warning {
  color: #fff;
}

app-fab-button.warning:before {
  background: #ffa400;
}

app-fab-button.light {
  color: #242424;
}

app-fab-button.light:before {
  background: #fafafa;
}

app-fab-button.primary {
  color: #fff;
}

app-fab-button.primary:before {
  background: #30a097;
}

app-fab-button.danger {
  color: #fff;
}

app-fab-button.dark:before {
  background: #404040;
}

app-fab-button.dark {
  color: #fff;
}

app-fab-button.success:before {
  background: #35a753;
}

app-fab-button.success {
  color: #fff;
}

app-fab-button.accepted:before {
  background: #4590c8;
}

app-fab-button.accepted {
  color: #fff;
}

app-fab-button.brand:before {
  background: #232c56;
}

app-fab-button.brand {
  color: #fff;
}

app-fab-button[size="small"] {
  width: 22px;
  height: 22px;
  font-size: 11px;
}

app-fab-button[size="small"]::before {
  width: 33px;
  height: 33px;
  font-size: 11px;
}

app-fab-button[size="xsmall"] {
  width: 14px;
  height: 14px;
  font-size: 10px;
}

app-fab-button.circle::before {
  border-radius: 30px;
}

app-fab-button.diamond::before {
  transform: rotate(45deg);
}


app-fab-button.pf::before {
  background-color: #d64b21;
}

app-fab-button.pf {
  color: #fff;
}

app-fab-button.ef::before {
  background-color: #ea987f;
}

app-fab-button.ef {
  color: #fff;

}

app-fab-button.fm::before {
  background-color: #236950;
}

app-fab-button.fm {
  color: #fff;
}

app-fab-button.nd::before {
  background-color: #498671;
}

app-fab-button.nd {
  color: #fff;
}

app-fab-button.jl::before {
  background-color: #2b48d9;
}

app-fab-button.jl {
  color: #f3f3f3;
}

app-fab-button.cc::before {
  background-color: #384283;
}

app-fab-button.cc {
  color: #f3f3f3;
}

app-fab-button.as::before {
  background-color: #8d658d;
}

app-fab-button.as {
  color: #f3f3f3;
}

app-fab-button.gm::before {
  background-color: #77be3c;
}

app-fab-button.gm {
  color: #f3f3f3;
}

app-fab-button.bl::before {
  background-color: #28b773;
}

app-fab-button.bl {
  color: #f3f3f3;
}

app-fab-button.ab::before {
  background-color: #43c81e;
}

app-fab-button.ab {
  color: #fff;
}

app-fab-button.cd::before {
  background-color: #8ca1dd;
}

app-fab-button.cd {
  color: #fff;
}

app-fab-button.jf::before {
  background-color: #e09ea9;
}

app-fab-button.jf {
  color: #fff;
}

app-fab-button.th::before {
  background-color: #6057dd;
}

app-fab-button.th {
  color: #fff;
}


app-fab-button.ap::before {
  background-color: #b14d4d;
}

app-fab-button.ap {
  color: #f3f3f3;
}

app-fab-button.bot_mail::before {
  background-color: #404040;
}

app-fab-button.bot_mail {
  color: #f3f3f3;
}

app-fab-button.m1::before {
  background-color: #404040;
}

app-fab-button.m1 {
  color: #f3f3f3;
}

app-fab-button.m2::before {
  background-color: #404040;
}

app-fab-button.m2 {
  color: #f3f3f3;
}

app-fab-button.m3::before {
  background-color: #404040;
}

app-fab-button.m3 {
  color: #f3f3f3;
}






app-fab-button.xm::before {
  background-color: #9E259E;
}

app-fab-button.xm {
  color: #f3f3f3;
}

app-fab-button.user::before {
  //background-color: ;
}

app-fab-button.prospect::before {
  background-color: #7ccd42;
  /*width: 24px;
  height: 24px;*/
}

app-fab-button.prospect {
  color: #fff;
}

app-fab-button.ancien_candidat::before {
  width: 24px;
  height: 24px;
  background-color: #393538;
}

app-fab-button.ancien_candidat {
  color: #fff;
}

app-fab-button.contrat_signe::before {
  background-color: #2cc2c8;
}

app-fab-button.embauche::before {
  background-color: #dd134c;
}

app-fab-button.success::before {
  background-color: green;
}

app-fab-button.embauche {
  color: #fff;
}

app-fab-button.salarie::before {
  background-color: #2f812f;

}

app-fab-button.salarie {
  color: #f3f3f3;
}

app-fab-button.ancien_salarie::before {
  background-color: black;
}

app-fab-button.ancien_salarie {
  color: #f3f3f3;
}

app-fab-button.salarie_testeur::before {
  //background-color: ;
}

app-fab-button.archive::before {
  background-color: #c4c4c4;
  width: 12px;
  height: 12px;
}

app-fab-button.archive {
  color: transparent;
}

.app-fab-label {
  font-weight: 300;
}

.m_DDS>ion-label>p:before {
  content: " 💸 ";
  font-weight: bold;
}

.m_CDT-S>ion-label>p:before {
  content: " 📝 ";
  font-weight: bold;
  color: green;
}

.m_CDT>ion-label>p:before {
  content: " 📄 ";
  font-weight: bold;
  color: green;
}

.m_CCPSPS-S>ion-label>p:before {
  content: " ✍ ";
  font-weight: bold;
  color: green;
}

.m_CCPSPS>ion-label>p:before {
  content: " 📜 ";
  font-weight: bold;
  color: green;
}



.m_F-CDT>ion-label>p:before {
  content: " 💼️ ";
  font-weight: bold;
  color: orange;
}


.m_TELE>ion-label>p:before {
  content: " 💻 ";
  font-weight: bold;
}

.m_DVA>ion-label>p:before {
  content: " 🚗 ";
  font-weight: bold;
}

.m_F-KADEOS>ion-label>p:before {
  content: " 🎁 ";
  font-weight: bold;
}

.m_ICT>ion-label>p:before,
.m_RIB>ion-label>p:before,
.m_PI>ion-label>p:before,
.m_CV>ion-label>p:before,
.m_JDD>ion-label>p:before,
.m_ASS>ion-label>p:before {
  content: " ∿ ";
  font-weight: bold;
}

.m_ACCOUNT>ion-label>p:before,
.m_USER>ion-label>p:before {
  content: " ❗ ";
  font-weight: bold;
  color: #1c1c1c;
}

app-form ion-label {
  color: #797979 !important;
}
